/**
 * 腾讯地图
 * 官方API地址：https://lbs.qq.com/webApi/javascriptGL/glGuide/glBasic
 * 加载Javascript API GL
 * 1、通过引入script标签加载API服务：<script charset="utf-8" src="https://map.qq.com/api/gljs?v=1.exp&key=YOUR_KEY"></script>
 * 2、异步加载API：通过动态创建script标签，并指定callback参数，在API加载后会自动调用callback指定回调函数，实现异步加载
 */
// 异步加载API
export function TMaps(key) {
  return new Promise(function (resolve, reject) {
    window.initMap = function () {
      resolve(TMap)
    }
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://map.qq.com/api/gljs?v=1.exp&libraries=service&callback=initMap&key=" + key;
    script.onerror = reject;
    document.body.appendChild(script);
  })
}


